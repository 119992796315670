(function($){

	$(document).ready(function() {

		// ON SCROLL EVENTS //

		var hasScrolled = false;
		var header = $(".main-header");
		var headerHeigh = $(".main-header").outerHeight();
		var scrollpos = 0;
		$(function() {
			$(window).scroll(function() {
				var scroll = $(window).scrollTop();
				scrollpos = scroll;
				if (scroll >= headerHeigh) {
					header.addClass("scrolled");
					hasScrolled = true;
				} else {
					header.removeClass("scrolled");
					hasScrolled = false;
				}
			});
		});

		// ON CLICK EVENTS //

		$(".siteMenu li.dropdown").each(function() {
			$(this).on("click", function(e){
				// If this is already opened - go to link.
				if ($(this).hasClass("dropdown_open")){

					return;
				}
				else{
					e.preventDefault();
				}

				// Find all already opened.
				var openDropDowns = $(".dropdown_open");
				console.log(openDropDowns);
				openDropDowns.each(function(){
					$(this).removeClass("dropdown_open");
					$(this).find('.sub-menu').slideUp();
				});

				// Open this.
				$(this).addClass("dropdown_open");
				$(this).find('.sub-menu').slideToggle();
			});
		});


		$('.mMenu').on('click', function(){
			$('.main-header').toggleClass('navOpen');
			$('#page-wrapper').toggleClass('navOpen');
			$('.siteMenu').slideToggle('navOpen');

		});


		$('.top-nav-mMenu').on('click', function(){
			$('.main-header').toggleClass('topnavOpen');
			$('.top-nav').slideToggle();
		});

		$('.submenuToggle').on('click', function(){
			$('.submenuToggle').toggleClass('submenuOpen');
			$('#sidemenu').slideToggle();
		});



		var headerHeight = $('.main-header').outerHeight();
		$('#main').css('padding-top', headerHeight + 'px');
		$( window ).resize(function() {
			var headerHeight = $('.main-header').outerHeight();
			$('#main').css('padding-top', headerHeight + 'px');
		});


		$(function() {
  $('vc_btn3-container a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
});

	});


})(jQuery);
