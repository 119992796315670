jQuery(document).ready(function($){

 $('.large_image_puff').each(function(){
	 $(this).find('.puff_inner').equalHeights();
 });


 $( window ).resize(function() {
	 $('.large_image_puff').each(function(){
		$(this).find('.puff_inner').equalHeights();
	 });
 });

});
