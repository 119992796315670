jQuery(document).ready(function ($) {
    var windowWidth = $(window).outerWidth();

    // Hero Swiper
    var swiper = new Swiper('.hero-slider-wrapper', {
        speed: 1500,
        autoplay: true,
        effect: 'fade',
        allowTouchMove: false,
        autoHeight: true,

        autoplay: {
            delay: 3800,
        },
        loop: {
            loop: true
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: false,
        },
    });

});
